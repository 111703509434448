@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,800;1,400;1,600;1,700&display=swap");

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}



/* header */
.sideGap {
  /* width: 95%;
  margin: auto; */
  padding: 0 60px;
}

.headerMain {
  position: sticky;
  top: 0px;
  box-shadow: 0px 5px 15px #3880ff1a;
  background-color: white;
  z-index: 999;
}

.headerDiv {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoImage img {
  height: 33px;
}

.header_Btns {
  display: flex;
  gap: 20px;
}

.sign_in {
  background-color: #3a80ff;
  outline: none;
  color: white;
  border: none;
}

.sign_in:hover {
  color: white !important;
}

.creat_accnt {
  color: #3a80ff;
  border: 1px solid #3a80ff;
  outline: none;
  background-color: #ebf2ff;
}

.headerBtnWidth {
  width: 135px;
  height: 37px;
}
.buttonBuy {
  width: 100px;
  height: 36px;
}

.paddingLeft {
  padding-left: 100px;
}
.fw500 {
  font-weight: 500;
}
/* ribben */
.ribbenMainDiv {
  color: white;
  background: linear-gradient(to right, #11475f, #3880ff);
  padding: 20px 1px;
  text-align: center;
  border-radius: 6px;
  /* border-bottom-left-radius: 10px; */
  /* border-bottom-right-radius: 10px; */
  box-shadow: 0px 5px 10px #00000033;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.ribben {
  border-right: 1px solid #dee2e6;
  cursor: pointer;
}

.lastRibbon {
  border-right: none !important;
}

.lastRibbon span {
  padding: 0px 22px;
}

/* section 1 */
.loaderDiv {
  display: grid;
  place-items: center;
  min-height: 390px;
}

.light_background {
  background-image: url("../images/bgWithPankh.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.pankhImage {
  position: absolute;
  content: "";
  left: 0px;
  right: 0px;
  top: 0px;
  height: 100%;
  background-image: url("../images/sidePankh.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bigImageDiv img {
  width: -webkit-fill-available;
  height: 490px;
  border-radius: 17px;
}

.bigImageDiv {
  position: relative;
  height: 100%;
  border-radius: 17px;
}

.shadowDiv {
  position: absolute;
  bottom: 0px;
  /* top: 0px; */
  left: 0px;
  right: 0px;
  height: 30%;
  background-image: url("../images/downShadow.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 14px;
}

.firstMain {
  display: flex;
  gap: 30px;
  padding: 50px 0px;
}

.squareImageDiv {
  height: 450px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 5px 20px #0000001a;
  border-radius: 17px;
  opacity: 1;
  overflow-y: scroll;
  padding-inline: 1rem;
}

.importntInfoHeading {
  margin-bottom: 21px;
  padding-top: 12px;
  color: rgb(56, 128, 255);
  position: sticky;
  top: 0;
  background: white;
}

/* section 2 */
/* .ImageCard {
    background-color: #edf3ff;
    padding: 8px 8px;
    border-radius: 17px;
    width: 200px;    
} */

.cardComp > .slick-slide.slick-active.slick-current {
  width: 550px !important;
}

.ant-carousel .slick-prev, .ant-carousel .slick-next{
  color: white !important;
  background: #3a80ff!important;
  border-radius: 44px!important;
  height: 30px;
  width: 30px;
}
.slick-arrow::before{
  color: white !important;
  font-size: 14px!important;
}

.ImageCard {
  background-color: #edf3ff;
  padding: 8px 8px;
  border-radius: 17;
  width: 22.5rem;
  height: 22.5rem;
}

.ImageCardCrousel {
  background-color: #edf3ff;
  padding: 8px 18px;
  border-radius: 17px;
  width: 95%;
  text-align: center;
}

.carousel-item {
  margin-right: 10px;
}

.image-wrapper {
  padding: 0 5px;
}

.ImageCard img {
  /* max-height: 153px; */
  border-radius: 10px;
  min-height: 22.5rem;
  height: 22.5rem;
}

.imageCardText {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  display: -webkit-inline-box;
  word-break: break-all;
  overflow: scroll;
  max-height: 54px;
}

/* sectin 3 vide playing*/
.redDot {
  color: red;
}

.redDot .MuiSvgIcon-root {
  /* margin-bottom: 10px; */
  font-size: 1.3rem;
}

.liveVideoMainDIv {
  /* background-color: #f6f9ff; */
  /* height: 300px; */
  /* border: 1px solid green; */
  /* margin-top: 90px; */
  text-align: center;
}

.liveVideoMainDIv img {
  text-align: center;
}

.streamingDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.GoLiveText {
  color: #3880ff;
  color: #3880ff;
  font-size: 1.2rem;
  font-weight: 600;
}

.streamingDiv .ant-switch-handle {
  top: 2px !important;
  height: 15px !important;
}

.streamingDiv .ant-switch {
  height: 19px !important;
}

.videoDiv {
  position: relative;
}

.videoDiv > div {
  height: 100% !important;
  width: 100% !important;
}

.videoDiv > div > video {
  border-radius: 17px;
}

.playBtn {
  position: absolute;
  top: 45%;
  left: 50%;
  right: 0;
  cursor: pointer;
}

.pauseBtn {
  position: absolute;
  top: 45%;
  left: 50%;
  right: 0;
  cursor: pointer;
  height: 80px;
}

.faclityDropDown select {
  border: 1px solid #3a80ff;
  border-radius: 5px;
  font-size: 1rem;
  background: transparent;
  outline: none !important;
  min-width: 100% !important;
}

/* secton4 other video */
.blueText {
  font-weight: 600;
  color: #3880ff;
  font-size: 2.5rem;
}

.blueTextOnly {
  color: #3880ff;
}

.blackText {
  font-weight: 500;
  font-size: 2.5rem;
}

.videoListBack {
  background-color: #edf3ff;
  display: flex;
  gap: 15px;
  margin-right: 20px;
  padding: 12px;
  border-radius: 17px;
  width: 200px;
  max-height: 200px;
}

.videoListBack video {
  height: 125px;
}

.LiveBoxDiv {
  position: absolute;
  top: 3px;
  left: 3px;
}

.playButton {
  background-color: #2b3590;
  height: 27px;
  padding: 0px 20px;
  color: white;
  margin-top: 10px;
  outline: none;
  border: none;
}

.playButton:hover {
  color: white !important;
}

.videoDetails p {
  margin-bottom: 5px !important;
}

.slider_btns {
  display: flex;
  justify-content: end;
}

.slider_btns .MuiSvgIcon-root {
  color: #3a80ff !important;
}

/* section5 download */
.downloadSecMain {
  /* border:1px solid red; */
}

.textMainDiv {
  padding-left: 100px;
}

.downloadBtns {
  text-align: left;
  margin-left: 1rem;
}

/* section6 contact */
.contactMain {
  padding-bottom: 30px;
  display: flex;
  gap: 80px;
}

.paddingWalaDiv {
  padding-left: 40px;
}

.formMainDiv input {
  background-color: #edf3ff;
  outline: none !important;
  border: none;
  padding: 15px 0px 15px 12px;
  margin-top: 10px;
}

.formMainDiv ::placeholder {
  color: black !important;
  font-weight: 400;
}

.contatcSendBtn {
  background-color: #3a80ff;
  outline: none;
  color: white;
  border: none;
  margin-top: 6px;
}

.contatcSendBtn:hover {
  color: white !important;
}

.messageField {
  resize: none !important;
  background-color: #edf3ff;
  border: none;
  margin-top: 10px;
}

/* video section on landing page */
#buttonComment {
  padding: 5px;
  border: none;
  height: 30px;
  width: 88%;
}

#buttonComment:focus-visible {
  border: none !important;
  outline: none !important;
}

.chatComment {
  box-shadow: 0px 0px 1px #000;
  padding: 8px;
  margin-bottom: 18px;
  border-radius: 4px;
}

.cardChat {
  width: 100%;
  background-color: white;
  height: 504px;
  position: relative;
}

.chatContainer {
  border: 1px solid #ccc;
  padding: 0px !important;
  height: 504px;
  border-radius: 5px;
}

.cardChat > .ant-card-body {
  padding: 0px !important;
}

.addcommentBox {
  border: 2px solid #cdc8c8;
  border-radius: 3px;
  position: absolute;
  width: 95%;
  bottom: 13px;
  right: 7px;
}

.vidThumbnail {
  width: 200px;
  height: 200px;
  object-fit: contain;
  cursor: pointer;
}
.vidThumbBox:focus-visible {
  outline: none !important;
}
.vidThumbnail:focus-visible {
  outline: none !important;
  border: none !important;
}

.live_chat_div {
  background: #edf5fb;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1rem;
}

.message_div {
  height: 90%;
  overflow-y: auto;
}

.height_manage {
  height: 506px;
}

.input_fields {
  height: 40px;
  width: 100%;
  outline: none;
  padding: 0 1rem;
  border: 1px solid #3881ff6c;
  border-radius: 5px;
  background: #fff;
  font-size: 0.8rem;
}

.common_btn {
  background: linear-gradient(to right, #104861, #3880ff);
  width: 100%;
  border: none;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  outline: none;
  font-family: "Roboto", sans-serif !important;
  font: 800 0.8rem "Nunito", sans-serif;
}

/* footer */
.footerLogo {
  /* background-color: #b3c5e2; */
  background-image: url("../images/footerBackImg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerLogo img {
  height: 130px;
}

.footer_LastPatti {
  background-color: #3a80ff;
  text-align: center;
  min-height: 42px;
  color: white;
  padding-top: 12px;
}

.footer_LastPatti img {
  width: 5%;
}

/* chart */
.chartMainDiv {
  /* border:1px solid; */
  /* height: 500px; */
  width: 100%;
  margin-top: 20px;
  /* background-color: white; */
  box-shadow: 0px 5px 20px #0000001a;
}

.patientChartMainDiv select {
  border: 1px solid #3a80ff;
  border-radius: 5px;
  font-size: 1rem;
  background: transparent;
  outline: none !important;
  width: 13%;
  padding: 3px;
}

.Task_chart select {
  border: 1px solid #3a80ff;
  border-radius: 5px;
  font-size: 1rem;
  background: transparent;
  outline: none !important;
  width: 13%;
  padding: 3px;
}

.pollMain {
  display: flex;
  gap: 13px;
  max-width: 100vw;
  overflow-x: auto;
}

.poll_image {
  border-radius: 6px;
  max-width: 17%;
  text-align: center;
  min-width: 17%;
}

.poll_image img {
  height: 140px;
  border-radius: 6px;
}

.scrollLivCalls {
  display: flex;
  gap: 2rem;
  overflow: scroll;
  height: 500px;
}
/* why US section */
.lines_container {
  position: relative;
}

.alignCol1 {
  width: 100%;
  padding-left: 25%;
}
.alignCol2 {
  width: 100%;
  padding-left: 40%;
}

@media screen and (min-width: 1300px) {
  .alignCol1 {
    padding-left: 32%;
  }
}

@media screen and (min-width: 2200px) {
  .alignCol1 {
    padding-left: 41%;
  }
  .image_manage {
    height: 550px;
  }
}


/* book streaming */
.streamingBooking {
  text-align: end;
  font-weight: 600;
  cursor: pointer;
}

.ant-btn:hover {
  color: #4096ff;
  border-color: #4096ff;
  background: #4096ff!important;
}